
import { ref, computed } from "vue"

interface Props {
  weight: string
  sex: string
}

export default {
  props: ["weight", "sex"],
  setup(props: Props) {
    const BMI_LIST = ["BMI<18.5", "18.5≤BMI<24", "24≤BMI<28", "28≤BMI"]
    const height = ref("")
    const bmi = ref(0)
    const kg = computed(() => Number(Number(props.weight).toFixed(1)))
    const getBMI = (ev: FocusEvent) => {
      bmi.value = Number(
        (kg.value / (Number(height.value) / 100) ** 2).toFixed(1)
      )
    }
    const bmiIndex = computed(() => {
      if (bmi.value && bmi.value !== Infinity) {
        if (bmi.value < 18.5) {
          return 0
        } else if (18.5 <= bmi.value && bmi.value < 24) {
          return 1
        } else if (24 <= bmi.value && bmi.value < 28) {
          return 2
        } else {
          return 3
        }
      } else {
        return -1
      }
    })
    return { getBMI, bmi, BMI_LIST, bmiIndex, kg, height }
  }
}
